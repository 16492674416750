<template>
  <v-container>
    <loader
      v-if="isLoading"
      message="Loading ...."
    />
    <div
      class="pt-6 pa-0"
      v-else
    >
      <s-tabs>
        <v-tab :to="{ name: 'appDetails' }">Details</v-tab>
        <v-tab :to="{ name: 'appWalletTxn' }">Wallet Transactions</v-tab>
        <v-tab :to="{ name: 'appReferrals' }">Referrals</v-tab>
        <v-tab :to="{ name: 'appSubscriptions' }">Subscriptions</v-tab>
        <v-tab :to="{ name: 'appLogs' }">Logs</v-tab>
        <v-tab :to="{ name: 'appErrorLogs' }">Error Logs</v-tab>
        <v-tab :to="{ name: 'appWebsites' }">Websites</v-tab>
      </s-tabs>
      <router-view :id="id"/>
    </div>
  </v-container>
</template>
<script>
import Loader from '@/components/cards/Loader'
import { SimpleEventBus } from '@simplifyd-systems/lib.client.core'
import { mapGetters } from 'vuex'
import { isUsernameNumeric } from '@/utils/fmt'
export default {
  name: 'AppDetails',
  components: {
    loader: Loader,
  },
  props: {
    id: {
      type: String,
      required: false,
    },
  },
  data () {
    return {
      isDeleting: false,
      isLoading: false,
      dialog: false,
      showDeleteDialog: false,
      showModal: this.$route.meta.showModal,
    }
  },
  computed: {
    ...mapGetters({
      user: 'user/currentUser',
    }),
    routeName () {
      return this.$route.name
    },
  },
  methods: {
    isUsernameNumeric,
    async getUserDetails () {
      if (this.isLoading) return

      this.isLoading = true
      await this.$store.dispatch('user/getUserDetails', this.$route.params.id)
      await this.$store.dispatch('user/getUserConfig', this.$route.params.id)
      await this.$store.dispatch('user/getUserWalletConfig', this.$route.params.id)
      this.isLoading = false
    },
    clearCurrentUserData () {
      this.$store.dispatch('user/clearCurrentUser')
      SimpleEventBus.$emit('updateHeader', null)
    },
  },
  watch: {
    user: {
      handler (user) {
        if (!user.username) return
        SimpleEventBus.$emit('updateHeader', `${this.isUsernameNumeric(this.user.username)}${this.user.username}`)
      },
      immediate: true,
    },
    id: {
      async handler (newId, oldId) {
        if (newId === oldId) return
        this.clearCurrentUserData()
        await this.getUserDetails()
      },
      immediate: true,
    },
  },
  async created () {
    await this.getUserDetails()
  },
  destroyed () {
    this.clearCurrentUserData()
  },
}
</script>
